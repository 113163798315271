<template>
  <div class="home">
    <Navigation />
    <div class="section bg-primary-3">
      <div class="container text-center">
        <h1>Market Updates</h1>
        <div class="text-large">Give your clients real time industry updates.</div>
      </div>
    </div>
    <Templates Category="market-updates" />
  </div>
</template>

<script>
import Navigation from "@/components/Navigation.vue";
import Templates from "@/components/TemplateCollection.vue";

export default {
  name: "home",
  components: {
    Navigation,
    Templates
  }
};
</script>
